import React, { Fragment, useEffect, useState } from 'react'
import PalaceView from '../components/PalaceView';
import '../styles/Wedding.css'
import { Navigate, useNavigate } from 'react-router-dom';
const Wedding: React.FC = () => {
    let navigate = useNavigate();
    // eslint-disable-next-line
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        let dataLogoContianer: any = document.getElementsByClassName("logo-container")
        let dataLogoImage: any = document.getElementsByClassName("background-image")
        dataLogoContianer[0].style.display = 'none';
        dataLogoImage[0].style.display = 'none';
    });
    return (
        !showPopup ?
            <Fragment>
                <PalaceView />
                <div className='wedding-container'>
                    <button className="button" onClick={() => navigate('/itinerary')}>Itinerary</button>
                    <button className="button" onClick={() => navigate('/info')}>Info</button>
                    <button className="button" onClick={() => navigate('/hotel')}>Hotel</button>
                    <button className="button" onClick={() => navigate('/travel')}>Travel</button>
                </div>
            </Fragment>
            : <Navigate to={"/"} />

    )
}
export default Wedding