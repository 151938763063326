import React, { Fragment, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "../styles/Story.css";
import { Navigate } from 'react-router-dom';
import { STORY_INFO } from '../Constants';

const Story: React.FC = () => {

    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        if (sessionStorage.getItem("isAdmin") === null || sessionStorage.getItem("isAdmin") === undefined) {
            setShowPopup(true);
        }
    }, []);

    useEffect(() => {
        let dataLogoContianer: any = document.getElementsByClassName("logo-container")
        let dataLogoImage: any = document.getElementsByClassName("background-image")
        dataLogoContianer[0].style.display = 'none';
        dataLogoImage[0].style.display = 'none';
    });

    return (
        !showPopup ? <Fragment>
            <div className="story-container">
                <img className="story-top-border" src="/assets/images/artifacts/cropped/top_border_story.png" alt="itinerary_title" />
                <img className="story-heading" src={STORY_INFO.title} alt="story_title" />
                <img className="story-photo-frame" src="/assets/images/artifacts/cropped/photos_frame_story_page.png" alt="photos_frame_story_page" />
                <div className="story-text">
                    <h2>{"It all started in a little auto..."}</h2>
                </div>
                <div className="story-text">
                    <p>{STORY_INFO.desc}</p>
                </div>
                <img className="story-bottom-border" src="/assets/images/artifacts/cropped/bottom_border_story.png" alt="itinerary_title" />
            </div>
        </Fragment> : <Navigate to={"/"} />
    );
};

export default Story;
