import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LandingPage.css';

interface LandingPageProps {
  setIsAdmin: (isAdmin: boolean) => void;
  setAnimate: (animate: boolean) => void;
  setShowLogo: (showLogo: boolean) => void;
}

const LandingPage: React.FC<LandingPageProps> = ({ setIsAdmin, setAnimate, setShowLogo }) => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.setItem('showCenterLogo', 'true');
  }, []);

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sessionStorage.setItem('showCenterLogo', 'false');
    if (password === 'ChicagoNYC') {
      sessionStorage.setItem("isAdmin", "false")
      setAnimate(true);
      setIsAdmin(false);
      setShowLogo(false);
      setTimeout(() => {
        navigate('/wedding');
        setAnimate(false);
      }, 500);
    } else if (password === 'ChicagoNYCAdmin') {
      sessionStorage.setItem("isAdmin", "true")
      setAnimate(true);
      setIsAdmin(true);
      setShowLogo(false);
      setTimeout(() => {
        navigate('/admin');
        setAnimate(false);
      }, 500);
    } else {
      setAnimate(false);
      setErrorMessage('Please enter the correct password!');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handlePasswordSubmit(e);
    }
  };

  return (
    <div className="landing-page">
      <div className="password-container">
        <div className="input-wrapper">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            className="password-input"
          />
          <button onClick={handlePasswordSubmit} className="arrow" aria-label="Submit Password">→</button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default LandingPage;
