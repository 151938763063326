import React, { useEffect, useState } from 'react'
import "../styles/Itinerary.css"
import ItineraryInfo from '../components/ItineraryInfo';
import { Navigate } from 'react-router-dom';
const Itinerary: React.FC = () => {
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        if (sessionStorage.getItem("isAdmin") === null || sessionStorage.getItem("isAdmin") === undefined) {
            setShowPopup(true)
        }
    }, [])

    useEffect(() => {
        let dataLogoContianer: any = document.getElementsByClassName("logo-container")
        let dataLogoImage: any = document.getElementsByClassName("background-image")
        dataLogoContianer[0].style.display = 'none';
        dataLogoImage[0].style.display = 'none';
    });
    return (
        !showPopup ? <ItineraryInfo /> : <Navigate to={"/"} />

    )
}
export default Itinerary